import { createGlobalStyle } from "styled-components"
import "@fontsource/inter"

const GlobalStyle = createGlobalStyle`
  *,
  *:after,
  *:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .clearfix:before,
  .clearfix:after {
    content: "";
    display: table;
  }
  .clearfix:after {
    clear: both;
  }
  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 62.5%;
  }
  :target:before {
    content: "";
    display: block;
    height: 100px;
    margin: -100px 0 0;
  }
  body {
  font-family: 'Inter', sans-serif; // Roboto Kullanılabilir
  font-size: 16px;
  color: #191A1E;
  background: #efefef;
  -webkit-font-smoothing: antialiased;
  top: 0;
  left: 0;
  margin: 0;
  }
  html,
  body {
  width: 100%;
  -webkit-font-smoothing: antialiased;
  }
  ::-webkit-scrollbar {
      display: none;
    }
  h1,
  h2,
  h3,
  h4,

  a,
  b {
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
  li,
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  h1 {
  font-size: 54px;
  line-height: 64px;
  font-family: 'Inter', sans-serif;
  margin-bottom: 24px;
}
h2 {
  font-size: 32px;
  line-height: 42px;
  font-family: 'Inter', sans-serif;
  margin-bottom: 24px;
}
h3 {
  font-size: 14px;
  line-height: 24px;
  font-family: 'Inter', sans-serif;
  letter-spacing: 2.5px;
  margin-bottom: 20px;
  opacity: 0.5;
  text-transform: uppercase;
}
h4 {
  font-size: 24px;
  line-height: 32px;
  font-family: 'Inter', sans-serif;
  margin-bottom: 10px;
}
p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}

strong {
  font-family: 'Inter', sans-serif;
}
a {
  text-decoration: none;
  color: #000;
  animation: ease-in;
}
p a {
  color: var(--primary);
}
p a:hover {
  text-decoration: underline;
}

h1.white,
h2.white,
h3.white,
h4.white,
p.white,
span.white {
  color: #fff;
}
h1.center,
h2.center,
h3.center,
h4.center,
p.center {
  text-align: center;
}

@media screen and (max-width: 1060px) {
  h1 {
    font-size: 42px;
    line-height: 50px;
  }
  h2 {
    font-size: 26px;
    line-height: 36px;
  }
  h4 {
    font-size: 21px;
    line-height: 30px;
  }
}

@media screen and (max-width: 640px) {
  h1 {
    font-size: 30px;
    line-height: 36px;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
  }
  h3 {
    font-size: 13px;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 19px;
    line-height: 26px;
    margin-bottom: 5px;
  }
}
/* ------------------------- Buttons -------------------------------------------------- */

.btn {
  height: 60px;
  display: inline-block;
  padding: 18px 30px 18px 30px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  color: #000;
  background: #fff;
  margin-right: 10px;
  transition: 0.3s;
}
.btn.white {
  background: #fff;
  color: #000;
}
.btn.black {
  background: #202124;
  color: #fff;
}
.btn.nobg {
  background: #202124;
  border: 1px solid #9aa0a6;
  color: #9aa0a6;
}
.btn .btn-icon {
  height: 24px;
  position: relative;
  float: right;
  width: 0;
}
.btn:hover {
  cursor: pointer;
  transition: ease .2s;
}
.btn:hover .btn-icon {
  transform: translate(4px, 0);
  opacity: 0.5;
}
.btn.nobg:hover {
  color: #fff;
  border: 1px solid #fff;
}

.btn-nav {
  position: absolute;
  transform: translate(0, -50%);
  right: 30px;
  top: 50%;
  height: 50px;
  padding: 13px 30px 13px 30px;
  margin: 0;
}

@media screen and (max-width: 1060px) {
  .btn-nav {
    position: fixed;
    top: auto;
    transform: translate(0, 0);
    width: calc(100% - 60px);
    text-align: center;
    bottom: 30px;
    left: 30px;
    opacity: 0;
    visibility: hidden;
  }

  body.active .btn-nav {
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 640px) {
  .btn {
    height: 54px;
    padding: 15px 20px 15px 20px;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 10px;
  }
}


/* ------------------------- All -------------------------------------------------- */

.section {
  float: left;
  width: 100%;
  position: relative;
  background: #f1f1f1;
  padding: 120px 0;
}

.section p span {
  max-width: 100%;
}


.section li {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 6px;
  opacity: 1;
  ::before {
  content: "•";
  font-size: 130%;
  line-height: 0;
  margin: 0 0.3rem 0 -0.25rem;
  position: relative;
  top: 0.08rem;
  color: rgb(25, 26, 30);
  }
}
ul {
  margin-bottom: 24px;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  width: 100%;
}

.flex-col {
  display: block;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
}

.wrap {
  max-width: 1060px;
  margin: 0 auto;
  padding-left: 80px;
  padding-right: 80px;
}

.wrap-wide {
  max-width: 1240px;
  margin: 0 auto;
  padding-left: 80px;
  padding-right: 80px;
}

.wrap-small {
  max-width: 840px;
  margin: 0 auto;
  padding-left: 80px;
  padding-right: 80px;
}

.wrap-text {
  max-width: 960px;
  margin: 0 auto;
  padding-left: 80px;
  padding-right: 80px;
}

.wrap-block {
  width: 1240px;
  padding-left: 80px;
  padding-right: 80px;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.sub-wrap {
  width: 50%;
  position: relative;
  float: left;
}
.sub-wrap span{
  opacity: 0.7;
  line-height: 64px;
}

.stripe {
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 1px;
  float: left;
}

.stripe.white {
  background: rgba(255, 255, 255, 0.25);
}

.stripe.dark {
  background: rgba(0, 0, 0, 0.15);
}

.stripe.white-nobg {
  background: #404040;
}

.icon {
  width: 40px;
  height: 40px;
  padding: 4px;
  float: left;
}

@media screen and (max-width: 1240px) {
  .wrap-block {
    width: 100%;
  }
}

@media screen and (max-width: 1060px) {
  .section {
    padding: 80px 0;
  }

  .wrap {
    max-width: 960px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .wrap-wide {
    max-width: 1140px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .wrap-small {
    max-width: 580px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
  }

  .wrap-text {
    max-width: 860px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .wrap-block {
    width: 100%;
    left: 0;
    top: 0;
    position: relative;
    transform: translate(0, 0);
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 640px) {
  .section {
    padding: 60px 0;
  }
}
/* ------------------------- Table -------------------------------------------------- */

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 24px;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even){background-color: #f2f2f2;}

table tr:hover {background-color: #ddd;}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #202124;
  color: white;
}

/* ------------------------- Logo -------------------------------------------------- */

nav .logo {
	position: absolute;
	left: 30px;
	top: 50%;
	transform: translate(0, -40%);
	z-index: 8;
  width: 200px;
}

@media screen and (max-width: 1060px){
	nav .logo {
		transform: translate(-10px, -40%) scale(.9);
	}
}

/* ------------------------- Navigation -------------------------------------------------- */

nav {
	position: fixed;
	width: 100%;
	top: 0;
	height: 80px;
	background: #202124;
  color: #fff;
	z-index: 7;
}

/* Navigation-bar  */

.navigation-bar {
	float: left;
	height: 80px;
	z-index: 7;
	border-top: 1px solid rgba(255, 255, 255, 0.25);
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
	position: relative;
	width: 100%;
}

nav .level-1 {
	height: 100%;
	text-align: center;
}

nav .level-1-a.right {
  margin-left: 20px;
}

nav .level-1 li {
	display: inline-block;
	height: 100%;
	padding: 0px 15px;
	position: relative;
}

nav .level-1 li:first-child {
	display: none;
}

nav .level-1 li a {
	display: inline-block;
	line-height: 24px;
	padding: 28px 0px;
	color: #9aa0a6;
	text-decoration: none;
	height: 100%;
	border-top: 1px solid rgba(30, 224, 127, 0);
}

nav .level-1 li a:hover {
	text-decoration: none;
  color:#e8eaed;
	border-top: 1px solid rgba(255, 255, 255, 0.5);
	cursor: pointer;
}

@media screen and (max-width: 1240px){
	nav .level-1 li {
		padding: 0 10px;
	}

	nav .level-1 li a {
		font-size: 15px;
	}
}

@media screen and (max-width: 1060px){

	nav {
		height: 80px;
	}

	.nav-height {
		height: 80px;
	}

	.navigation-bar {

		height: 80px;
		border-top: 1px solid rgba(255, 255, 255, 0);
	}

	nav .level-1 {
		background: #202124;
    color: #9aa0a6;
		height: 100vh;
		position: absolute;
		top: 79px;
		padding: 15px 0;
		width: 100%;
		opacity: 0;
		visibility: hidden;
	}

	nav .level-1 li {
		width: 100%;
		height: 44px;
		padding: 0;
	}

	nav .level-1 li:first-child {
		display: inline-block;
	}

	nav .level-1 li .level-1-a, body.scrolled .level-1 li .level-1-a {
		padding: 10px 30px;
		width: 100%;
		text-align: left;
		height: 100%;
		border-left: 1px solid rgba(30, 224, 127, 0);
	}

	nav .level-1 li a:hover {
		border-top: 1px solid rgba(30, 224, 127, 0);
	}


	.navigation-bar {
		float: left;
	}

	.level-1 {
		background: #fff;
	}

	body.active nav .level-1 {
		opacity: 1;
		visibility: visible;
	}

	body.active .navigation-bar {
    background: #202124;
	}

	nav .level-1 a:hover, nav .level-1 .level-2 a:hover {
		color: rgb(48,35,92);
	}

	nav .level-1 .level-2 .resource-nav-external svg path {
		fill: #fff;
	}
}


/* ------------------------- Nav Icon -------------------------------------------------- */

.nav-icon {
	position: fixed;
	right: 0;
	top: 0px;
	width: 80px;
	height: 80px;
	cursor: pointer;
	z-index: 8;
	display: none;
 }

 .hamburger-bar {
	width: 20px;
	height: 2px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #9aa0a6;
 }

 .hamburger-bar:before,
 .hamburger-bar:after
 {
	content: '';
	position: absolute;
	width: 20px;
	height: 2px;
	background: #9aa0a6;
 }

 .hamburger-bar:before {
	transform: rotate(0deg) translate(0px,-7px);
 }

 .hamburger-bar:after {
	 transform: rotate(0deg) translate(0px,7px);
 }
 body.active .nav-icon .hamburger-bar {
	background: rgba(0, 0, 0, 0);
 }

 body.active .nav-icon .hamburger-bar:before {
	transform: rotate(-45deg) translate(0px,0px);
 }

 body.active .nav-icon .hamburger-bar:after {
	transform: rotate(45deg) translate(0px,0px);
 }

 @media screen and (max-width: 1060px){
	.nav-icon {
		display: block;
  }
}

/* ------------------------- Footer -------------------------------------------------- */

.footer {
    background: #202124;
    padding: 80px 0 0 0;
    color: #9aa0a6;
  }

  .footer li {
    opacity: 1;
    ::before {
      display: none;
    }
  }
  .footer .footer-top {
    float: left;
    width: 100%;
  }

  .inside p {
    color: #9aa0a6;
    font-size: 12px;
  }

  .footer .footer-top .inside {
    float: left;
    padding-bottom: 70px;
    width: 20%;
  }

  .footer .footer-top .inside:nth-child(1) {
    width: 34%;
  }
  .footer .footer-top .inside:nth-child(2) {
    width: 28%;
  }
  .footer .footer-top .inside:nth-child(3) {
    width: 21%;
  }
  .footer .footer-top .inside:nth-child(4) {
    width: 15%;
  }

  .footer h3 {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 12px;
  }

  .footer ul li a {
    color: #9aa0a6;
    padding: 5px 0;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
  }

  .footer .products-ul li a {
    font-size: 14px;
    padding: 5px 0;
    line-height: 20px;
  }

  .footer ul li a:hover {
    color: #e8eaed;
  }

  .footer .external-links {
    padding-top: 28px;
  }

  .footer ul li a .btn-icon {
    height: 18px;
    position: relative;
    float: right;
    width: 18px;
    opacity: 0.5;
    margin-left: 3px;
  }

  .footer ul li a:hover .btn-icon {
    transform: translate(2px, -2px);
  }

  .footer ul li.blog-external-link a:hover svg {
    transform: translate(2px, -2px);
  }

  .footer-bottom {
    padding: 40px 0;
    float: left;
    width: 100%;
  }

  .footer-bottom span,
  .footer-bottom a {
    font-size: 14px;
    line-height: 18px;
    opacity: 0.7;
    color: #e8eaed;
  }

  .footer-bottom .inside {
    float: right;
  }

  .footer-bottom a {
    margin-left: 15px;
  }

  .footer-bottom a:hover {
    opacity: 1;
  }

  .disclaimer-footer {
    font-size: 11px;
    line-height: 1.5;
    margin-top: -30px;
    padding-right: 30px;
    max-width: 320px;
    float: left;
  }

  .social-links {
    float: left;
    width: 100%;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .social-links li {
    display: inline-block;
    margin-right: 3px;
  }

  .social-links li .s-icon {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    align-content: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.25);
    border: 1px solid rgba(255, 255, 255, 0);
  }

  .social-links li .s-icon svg {
    display: block;
    width: 50%;
  }

  .social-links li .s-icon svg path {
    fill: #fff;
  }

  .social-links li .s-icon:hover {
    border: 1px solid #e8eaed;
    opacity: 1;
  }

  .footer .logo img {
    height: 50px;
    width: 200px;
  }
  .footer .logo {
    width: 150px;
  }

  @media screen and (max-width: 850px) {
    .footer {
      padding: 40px 0 0 0;
    }

    .footer .wrap-wide {
      max-width: 660px;
    }

    .footer .footer-top .inside:nth-child(1) {
      width: 60%;
    }
    .footer .footer-top .inside:nth-child(2) {
      width: 40%;
    }
    .footer .footer-top .inside:nth-child(3) {
      width: 60%;
    }
    .footer .footer-top .inside:nth-child(4) {
      width: 40%;
    }
  }

  @media screen and (max-width: 660px) {
    .footer .footer-top .inside:nth-child(1) {
      width: 50%;
    }
    .footer .footer-top .inside:nth-child(2) {
      width: 50%;
    }
    .footer .footer-top .inside:nth-child(3) {
      width: 50%;
    }
    .footer .footer-top .inside:nth-child(4) {
      width: 50%;
    }

    .footer-bottom .inside {
      float: left;
      margin-top: 20px;
    }

    .footer-bottom a {
      margin-right: 15px;
      margin-left: 0;
      opacity: 1;
    }

    .footer h3 {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 660px) {
    .footer .footer-top .inside {
      padding-bottom: 50px;
    }

    .footer .footer-top .inside:nth-child(1) {
      width: 100%;
    }
    .footer .footer-top .inside:nth-child(2) {
      width: 100%;
    }
    .footer .footer-top .inside:nth-child(3) {
      width: 100%;
    }
    .footer .footer-top .inside:nth-child(4) {
      width: 100%;
    }

    .footer .logo {
    }

    .disclaimer-footer {
      width: 100%;
      margin-bottom: 0;
      margin-top: 0;
      padding: 0;
      max-width: 100%;
    }
  }

  /* ------------------------- Sub Footer Blocks -------------------------------------------------- */

  .sub-footer-blocks {
    background: #202124;
    text-align: center;
    color: #fff;
    overflow: hidden;
  }

  .sub-footer-blocks .wrap-wide {
    position: relative;
  }

  .sub-footer-blocks .left {
    position: absolute;
    left: 150px;
    top: 50%;
    transform: translate(-180px, -50%) scale(1.5);
    opacity: 1;
  }

  .sub-footer-blocks .right {
    position: absolute;
    right: 150px;
    top: 50%;
    transform: translate(180px, -50%) scale(1.5);
    opacity: 1;
  }

  .sub-footer-blocks h2 {
    font-size: 42px;
    line-height: 62px;
  }


  @media screen and (max-width: 1060px) {
    .sub-footer-blocks .left {
      left: 40px;
    }

    .sub-footer-blocks .right {
      right: 40px;
    }

    .sub-footer-blocks h2 {
      font-size: 32px;
      line-height: 42px;
    }
  }

  @media screen and (max-width: 700px) {
    .sub-footer-blocks .left {
      left: 0px;
    }

    .sub-footer-blocks .right {
      right: 0px;
    }
  }


/* ------------------------- Home Page -------------------------------------------------- */

.home-header {
    padding: 0;
    background: center center;
    background-size: cover;
    height: 100vh;
    background: #202124;
    color: #fff;
    overflow: hidden;
  }
  .home-header .header-cubes {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(0%, -50%);
    display: flex;
  }

  .home-header .wrap-block div {
    z-index: 2;
    position: relative;
    max-width: 600px;
  }

  .home-header h3 {
    margin-top: 20px;
  }

  .home-header h1 {
    font-size: 62px;
    line-height: 72px;
    font-weight: 600;
  }

  /* Home Header - Bottom  */

  .home-header-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 140px;
  }

  .home-header-bottom .scroll-down {
    height: 60px;
    line-height: 24px;
    padding: 18px 20px 18px 0;
    float: left;
    color: #fff;
    text-decoration: none;
  }

  .home-header-bottom .scroll-down .arrow-scroll {
    float: left;
    height: 24px;
    width: 24px;
    transform: translate(0, -1px) rotate(90deg);
    margin-right: 20px;
  }

  .home-header-bottom .scroll-down:hover {
    color: rgba(255, 255, 255, 0.7);
  }


  @media screen and (max-width: 1060px) {
    .home-header {
      height: auto;
      padding: 200px 0 360px 0;
    }

    .home-header .wrap-block {
      padding-left: 80px;
      padding-right: 80px;
    }

    .home-header h1 {
      font-size: 54px;
      line-height: 64px;
    }

    .home-header-bottom {
      height: 220px;
    }

    .home-header-news {
      width: 100%;
      height: 140px;
      bottom: 0;
    }

    .home-header h3 {
      margin-top: 80px;
    }

  }

  @media screen and (max-width: 740px) {
    .home-header .wrap-block {
      padding-left: 30px;
      padding-right: 30px;
    }

    .home-header-bottom {
      height: 80px;
    }

    .home-header .header-cubes {
    width: 200px;
    position: absolute;
    top: 18%;
    right: 10%;
    transform: translate(0%, -50%);
    display: flex;
    opacity: 0.7;
    }
  }

  @media screen and (max-width: 640px) {
    .home-header {
      height: auto;
      padding: 120px 0 200px 0;
    }

    .home-header h1 {
      font-size: 42px;
      line-height: 52px;
      margin-bottom: 30px;
    }
  }


  /* ------------------------- Standart Page Hero -------------------------*/

  .standart-hero {
    background: #202124;
    color: #fff;
    padding: 160px 0 0 0;

  }



  .standart-hero .sub-wrap {
    width: 100%;
    padding-right: 20%;
    padding-bottom: 70px;
  }

  @media screen and (max-width: 1060px) {
    .standart-hero {
      padding: 100px 0 0 0;
    }

    .standart-hero .sub-wrap {
      padding-right: 30%;
    }
  }

  @media screen and (max-width: 640px) {
    .standart-hero {
      padding: 80px 0 0 0;
    }

    .standart-hero .sub-wrap {
      padding-right: 0;
      padding-bottom: 80px;
    }
  }


  /* ------------------------- Sections Services -------------------------*/

  .services {
    text-align: center;
    padding-bottom: 100px;
    background: #202124;
    color: #fff;
  }
  .services a {
    color: #fff;
  }
  .services h2 {
    margin-bottom: 30px;
  }
  .services .service {
    width: 33%;
    float: left;
    padding: 40px 15px 15px 15px;
  }

  .services .service .icon-service {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    position: relative;
    margin-bottom: 10px;
  }

  .services .service:hover {
    background: rgba(64, 64, 64,  0.2);
  }

  .services h4 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 900px) {
    .services {
      padding-bottom: 40px;
    }

    .services .service {
      width: 50%;
      padding: 40px 15px 15px 15px;
    }
  }

  @media screen and (max-width: 640px) {
    .services .wrap {
      padding-left: 10px;
      padding-right: 10px;
    }

    .services .service {
      width: 50%;
      padding: 15px 10px 0px 10px;
      height: 300px;
    }

    .services .service p {
      font-size: 14px;
      line-height: 20px;
    }
  }

   /* ------------------------- Sections Steps  -------------------------*/

   .steps {
    background: #202124;
    color: #fff;
    text-align: center;
  }

  .steps .steps-item {
    float: left;
    width: 33.333%;
    text-align: center;
    position: relative;
    padding: 0 30px;
  }

  .steps .steps-visual {
    position: relative;
    display: inline-block;
    height: 140px;
    padding: 0 30px;
  }

  .steps .steps-visual .steps-shape img {
    top: 0;
    left: 0;
    width: 120px;
  }

  .steps p {
    margin-bottom: 0;
  }

  @media screen and (max-width: 900px) {
    .steps {
      padding-bottom: 20px;
    }

    .steps .steps-item {
      width: 100%;
      margin-bottom: 40px;
    }
  }

/* ------------------------- Blog Hero  -------------------------*/

  .blog-hero {
      text-align: left;
      background: #202124;
      color: #fff;

  }
  .blog-hero h1 {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 24px;
  }

  .blog-hero.right .sub-wrap:first-child {
    padding: 0 100px 0 40px;
  }
  .blog-hero .img {
    width: 100%;
    display: block;
  }

  @media screen and (max-width: 900px) {
    .blog-hero {
      padding-bottom: 40px;
    }
  }

  @media screen and (max-width: 640px) {
    .blog-heros .wrap {
      padding-left: 10px;
      padding-right: 10px;
    }
    .blog-hero.right .sub-wrap:nth-child(1),
    .blog-hero.right .sub-wrap {
      padding: 0;
      width: 100%;
      }
  }
  a img {
    width: 100%;
  }
/* ------------------------- Blog ToC -------------------------------------------------- */

.table-of-contents {
  align-items: center;
  margin-top: -180px;
  ::before {
    line-height: 48px;
    font-weight: bold;
    font-size: 18px;
    content: "İçindekiler:" ;
  }
    background: #efefef none repeat scroll 0 0;
    border: 1px solid #aaa;
    display: table;
    font-size: 95%;
    margin-bottom: 1em;
    padding: 20px;
    width: 100%;
}
.table-of-contents ul {
  margin-bottom: 0;
}
.table-of-contents li {
  ::before {
  content: "-" ;
  }
}
.table-of-contents li, .table-of-contents  ul, .table-of-contents  ul li{
    list-style: outside none none !important;
}

/* ------------------------- Blog Author -------------------------------------------------- */
.blog-author {
	float: left;
	width: 100%;
  color: #fff;
}

.blog-author:hover h3 {

  opacity: 1;
}

.blog-author h3 {
	margin: 0;
	line-height: 30px;
	margin-top: 0px;
	font-size: 18px;
}

.blog-author-photo {
	width: 60px;
	height: 60px;
	border-radius: 30px;
	float: left;
	margin-right: 20px;
}

.blog-author p {
	color: #fff;
	font-weight: 500;
	font-size: 14px;
	margin: 0;
	line-height: 20px;
	margin-top: 5px;
	opacity: .7;
}


/* ------------------------- Sections Landing Steps  -------------------------*/


  .landing-steps {
    text-align: left;
    background: #fff;
  }

  .landing-steps h2 {
    margin-bottom: 30px;
  }
  .landing-steps li {
    opacity: 1;
  }
  .landing-steps.right .sub-wrap:first-child {
    padding: 0 100px 0 40px;
  }
  .landing-steps .img {
    width: 100%;
    display: block;
  }

  .landing-steps .service {
    width: 50%;
    float: left;
    padding: 40px 15px 15px 15px;
  }

  .landing-steps .service .icon-service {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    position: relative;
    margin-bottom: 10px;
  }

  .landing-steps h4 {
    margin-bottom: 5px;
  }

  @media screen and (max-width: 900px) {
    .landing-steps {
      padding-bottom: 40px;
    }

    .landing-steps .service {
      width: 100%;
      padding: 40px 15px 15px 15px;
    }
  }

  @media screen and (max-width: 640px) {
    .landing-steps .wrap {
      padding-left: 10px;
      padding-right: 10px;
    }
    .landing-steps.right .sub-wrap:nth-child(1),
    .landing-steps.right .sub-wrap {
      padding: 0;
      width: 100%;
      }
    .landing-steps .service {
      width: 100%;
      padding: 15px 10px 0px 10px;
      height: auto;
    }

    .landing-steps .service p {
      font-size: 14px;
      line-height: 20px;
    }
  }

  /* ------------------------- Contact Form -------------------------------------------------- */

.Contact-Wrapper {
  padding: 100px;
}
@media screen and (max-width: 640px) {
    .Contact-Wrapper {
      padding: 0;
    }
  }
  form {
    display: block;
  }
  .ContactSection-Form {
    padding-top: 32px;
  }
  .ContactSection-FieldGroup {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
  }
  .ContactSection-Fieldset {
    margin-bottom: 18px;
  }
  fieldset {
    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
  }
  .ContactSection-Field {
    appearance: none;
    width: 100%;
    padding: 16px 20px;
    border-radius: 0px;
    border: 1px solid rgb(29, 25, 41);
    font-family: inherit;
    font-size: 18px;
    background-color: rgb(255, 255, 255);
  }
  input {
    text-rendering: auto;
    color: black;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    -webkit-rtl-ordering: logical;
    cursor: text;
    background-color: rgb(59, 59, 59);
    margin: 0em;
  }
  .ContactSection-FormError {
    color: rgb(254, 2, 2);
    display: block;
  }
  textarea {
    appearance: none;
    width: 100%;
    padding: 16px 20px;
    border-radius: 0px;
    border: 1px solid rgb(29, 25, 41);
    font-family: inherit;
    font-size: 18px;
    background-color: rgb(255, 255, 255);
  }

/* ------------------------- Media Thumbs - Blog -------------------------------------------------- */

.media-articles .flex-row {
    flex-wrap: nowrap;
    align-items: flex-start;
}

.media-articles .flex-col:last-child {
    width: 400px;
}


.media-articles h2 {
    margin-bottom: 10px;
}

.media-card .flex-col:last-child {
    width: 120px;
    margin-left: 40px;
}

.media-card .flex-col .media-logo {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    justify-content: center
}

.media-card .flex-col .media-logo .img {
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
    max-height: 100%;
    object-fit: contain;
    opacity: 1;
}

.media-card:hover .media-logo .img {
    opacity: 0.2;
    display: inline-block;
}

.media-card .flex-col .media-logo .btn {
    position: absolute;
    white-space: nowrap;
    height: 50px;
    padding: 14px 20px 13px 20px;
    opacity: 0;
    transform: translate(-10px, 0);
}

.media-card:hover .media-logo .btn {
    opacity: 1;
    transform: translate(0, 0);
}

.media-card {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 40px 0 35px 0;
}

.media-card:last-child {
    border-bottom: 0;
}

.media-card h4 {
    display: block;
    max-width: 640px;
    font-size: 19px;
    line-height: 24px;
    margin-bottom: 10px;
}

.media-card .card-info {
    width: 100%;
    display: block;
}

.media-card .card-info span {
    font-size: 14px;
    opacity: 1;
    color: #000;
}

.media-card .card-info span:first-child {
    opacity: .7;
}

.media-card .card-info span:last-child {
    opacity: .5;
}

.media-card:hover .card-info span:first-child {
    color: #202124;
    opacity: 1;
}

.media-articles .media-contact-block {
    width: 100%;
    background: #000;
    padding: 40px 30px;
    width: 340px;
    margin-left: 60px;
    color: #fff;
}

.media-articles .media-contact-block h4 {
    font-size: 19px;
    line-height: 24px;
}

.media-articles .media-contact-block p {
    opacity: 1;
    color: rgba(255, 255, 255, 0.7);
}

.media-articles .media-contact-block p:last-child {
    margin: 0;
}

.media-articles .more-media-btn {
    margin-top: 40px;
}

@media screen and (max-width: 1000px){

    .media-articles .flex-row {
        flex-wrap: wrap;
    }

    .media-articles .flex-col:last-child {
        width: 100%;
    }

    .media-card .flex-col:last-child {
        width: 120px;
    }

    .media-articles .media-contact-block {
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
    }
}

@media screen and (max-width: 540px){

    .media-articles .media-card .flex-col:first-child {
        order: 3;
    }

    .media-articles .media-card {
        flex-direction: column;
    }

    .media-articles .media-card .flex-col:last-child {
        margin-left: 0;
        width: 100%;
        margin-bottom: 10px;
        margin-top: -20px;
    }

    .media-card .flex-col .media-logo .btn {
        display: none;
    }

    .media-card .flex-col .media-logo img, .media-card:hover .media-logo img {
        opacity: 1;
    }

    .media-card .flex-col .media-logo {
        justify-content: flex-start;
    }
}

  /* ------------------------- Media Featured - In the News -------------------------------------------------- */

.media-featured {
    background: #F1F1F1;
}

.media-featured .flex-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-between;
}

.media-featured .media-card {
    width: calc(33.333% - 20px);
    flex-direction: column;
    border-bottom: 0;
    align-items: stretch;
    padding: 20px 0 0 0;
}

.media-featured .media-card .flex-col:first-child {
    order: 3;
}

.media-featured .media-card .flex-col:last-child {
    width: 100%;
    padding-top: 60%;
    background: #202124;
    margin: 0;
    margin-bottom: 25px;
    position: relative;
}

.media-featured .media-card .flex-col .media-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.media-featured .media-card .flex-col .media-logo .btn {
    transform: translate(0, 10px);
}

.media-featured .media-card:hover .flex-col .media-logo .btn {
    transform: translate(0, 0);
}

.media-featured .media-card .flex-col .media-logo img {
    transform: scale(1.5);
}

@media screen and (max-width: 1000px){

    .media-featured .flex-row {
        flex-wrap: wrap;
    }

    .media-featured .media-card {
        width: calc(50% - 15px);
    }
}

@media screen and (max-width: 540px){

    .media-featured .media-card {
        width: 100%;
    }
}
/* ------------------------- Whatsapp - Google Maps -------------------------------------------------- */

.google-map {
  position: fixed;
  width: 60px;
  height: 80px;
  bottom: 0;
  text-align: center;
  right: 15px;
  z-index: 100;
  .my-float {
    margin-top: 10px;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
}
.whats-app {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 25px;
  text-align: center;
  right: 10px;
  z-index: 100;

.my-float {
  margin-top: 10px;
}
@media screen and (min-width: 600px) {
    display: none;
  }
}
.map-frame {
    width: 100%;
    height: 100%;
    position: relative;
}

/* ------------------------- Default - Textblock -------------------------------------------------- */

.textblock {
    padding: 80px 0 80px 0;
}

.textblock ul {
    display: flex;
    flex-wrap: wrap;
    float: left;
    width: 100%;
}

.textblock ul li {
    margin-bottom: 10px;
    position: relative;
}

.textblock ul li:before {
    display: none;
}

@media screen and (max-width: 1060px){
    .textblock {
        padding: 80px 0 60px 0;
    }
}

@media screen and (max-width: 640px){
    .textblock {
        padding: 40px 0 20px 0;
    }
}




/* ------------------------- Reviews -------------------------------------------------- */

.reviews {
    text-align: left;
    padding-bottom: 100px;
  }

  .reviews .customer {
    width: 50%;
    float: left;
    padding: 40px 15px 15px 15px;
  }

  .reviews .customer .icon-customer {
    display: inline-block;
    width: 100px;
    height: 20px;
    border-radius: 30px;
    position: relative;
  }

  .reviews h4 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 900px) {
    .reviews {
      padding-bottom: 40px;
    }

    .reviews .customer {
      width: 50%;
      padding: 40px 15px 15px 15px;
    }
  }

  @media screen and (max-width: 640px) {
    .reviews .wrap {
      padding-left: 10px;
      padding-right: 10px;
    }

    .reviews .customer {
      width: 100%;
      padding: 15px 10px 0px 10px;
      height: auto;
    }

    .reviews .customer p {
      font-size: 14px;
      line-height: 20px;
    }
  }


/* ------------------------- Animations -------------------------------------------------- */

.navigation-bar, nav, nav a, .nav-icon, .hamburger-bar, .hamburger-bar:before, .hamburger-bar:after, .black-overlay, .drop-nav, .active, .drop-nav .wrap, .drop-icon, .level-1, .partner-nav, .partner-nav li {
	-webkit-transition: all .25s ease-in-out;
	-moz-transition: all .25s ease-in-out;
  	-ms-transition: all .25s ease-in-out;
  	-o-transition: all .25s ease-in-out;
  	transition: all .25s ease-in-out;
 }


`

export default GlobalStyle
