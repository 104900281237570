import React from "react"
import { Link } from "gatsby"
import Logo from "../../images/logo.svg"
import Left from "../../images/footer-left.svg"
import Right from "../../images/footer-right.svg"

const d = new Date()
const y = d.getFullYear()

const Footer = () => {
  return (
    <div>
      <div className="section sub-footer-blocks">
        <div className="wrap-wide">
          <div className="left">
            <Left />
          </div>
          <div className="right">
            <Right />
          </div>
          <div className="wrap-small">
            <h3>İZMİR AVUKAT - İZMİR HUKUK BÜROSU - İZMİR AVUKATLIK BÜROSU</h3>
            <h2>Avukat ihtiyacınız mı var?</h2>
            <p>
              İzmir Ceza Avukatı İpek Kul, sizin için her türlü yargılama ve
              dava dosyanız hakkında bilgi vermektedir. Boşanma avukatı arıyor
              ve İzmir’de bir boşanma avukatı bulmak istiyorsanız bize ulaşın.
            </p>
            <Link to="/iletisim" className="btn nobg">
              Hukuki Danışmanlık İsteyin
            </Link>
          </div>
        </div>
      </div>
      <div className="stripe white-nobg" />
      <div className="section footer">
        <div className="wrap-wide">
          <div className="footer-top">
            <div className="inside">
              <div className="logo">
                <Link to="/">
                  <Logo />
                </Link>
              </div>
              <ul className="social-links">
                <li>
                  <a
                    className="s-icon"
                    href="https://twitter.com/avipekkul"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M22.459,5.5a8.763,8.763,0,0,1-2.471.678A4.337,4.337,0,0,0,21.88,3.794,8.907,8.907,0,0,1,19.144,4.83,4.3,4.3,0,0,0,11.7,7.768a4.446,4.446,0,0,0,.111.983A12.194,12.194,0,0,1,2.935,4.266a4.226,4.226,0,0,0-.582,2.166,4.307,4.307,0,0,0,1.914,3.584,4.292,4.292,0,0,1-1.949-.539V9.53A4.306,4.306,0,0,0,5.77,13.753a4.342,4.342,0,0,1-1.935.075,4.318,4.318,0,0,0,4.028,2.989,8.629,8.629,0,0,1-5.339,1.842A9.277,9.277,0,0,1,1.5,18.6a12.254,12.254,0,0,0,6.613,1.932A12.159,12.159,0,0,0,20.361,8.3c0-.183,0-.367-.013-.551A8.69,8.69,0,0,0,22.5,5.516Z" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    className="s-icon"
                    href="https://www.linkedin.com/in/ipek-kul-652277225"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M19.039,19.043H16.078V14.4c0-1.106-.023-2.53-1.544-2.53-1.544,0-1.78,1.2-1.78,2.449v4.722H9.792V9.5h2.845v1.3h.039a3.12,3.12,0,0,1,2.808-1.542c3,0,3.556,1.975,3.556,4.546v5.238ZM6.447,8.194A1.72,1.72,0,1,1,8.168,6.473,1.719,1.719,0,0,1,6.447,8.194ZM7.932,19.043H4.963V9.5H7.932ZM20.521,2H3.476A1.458,1.458,0,0,0,2,3.441V20.559A1.458,1.458,0,0,0,3.476,22H20.518A1.463,1.463,0,0,0,22,20.559V3.441A1.464,1.464,0,0,0,20.518,2Z" />
                    </svg>
                  </a>
                </li>
              </ul>
              <div className="disclaimer-footer">
                <div>
                  İzmir avukat için hemen bizimle iletişime geçin. Avukatlık
                  büromuzun kurucusu Av. İPEK KUL, İzmir Barosu’na bağlı olarak
                  İzmir’de avukatlık yapmaktadır. İzmir boşanma avukatı, İzmir
                  ceza avukatı, İzmir icra avukatı, İzmir gayrimenkul avukatı,
                  İzmir miras avukatı sorunları çözmek veya hukuk hizmetlerini
                  yerine getirmek için İzmir'de avukat arayanlar çıkarlarınızı
                  geliştirmek için tüm tecrübemizle hukuk bilgisini sizinle
                  paylaşıyoruz.
                </div>
              </div>
            </div>
            <div className="inside">
              <h3>Çalışma Alanlarımız</h3>
              <ul className="products-ul">
                <li>
                  <Link to="/calisma-alanlari/gayrimenkul-avukati">
                    Gayrimenkul Avukatı
                  </Link>
                </li>
                <li>
                  <Link to="/calisma-alanlari/icra-iflas-avukati">
                    İcra ve İflas Avukatı
                  </Link>
                </li>
                <li>
                  <Link to="/calisma-alanlari/bosanma-avukati">
                    Boşanma Avukatı
                  </Link>
                </li>
                <li>
                  <Link to="/calisma-alanlari/deport-avukati">
                    Deport Avukatı
                  </Link>
                </li>
                <li>
                  <Link to="/calisma-alanlari/ceza-avukati">Ceza Avukatı</Link>
                </li>
                <li>
                  <Link to="/calisma-alanlari/is-avukati">İş Avukatı</Link>
                </li>
              </ul>
            </div>
            <div className="inside">
              <h3>SİTE HARİTASI</h3>
              <ul>
                <li>
                  <Link className="level-1-a" to="/sikca-sorulan-sorular">
                    Sıkça Sorulan Sorular
                  </Link>
                </li>
                <li>
                  <Link className="level-1-a" to="/calisma-alanlari">
                    Çalışma Alanları
                  </Link>
                </li>
                <li>
                  <Link className="level-1-a" to="/iletisim">
                    Avukata Sor
                  </Link>
                </li>
                <li>
                  <Link className="level-1-a" to="/makaleler">
                    Makaleler
                  </Link>
                </li>
                <li>
                  <Link className="level-1-a" to="/kurumsal">
                    Kurumsal
                  </Link>
                </li>
                <li>
                  <Link className="level-1-a" to="/iletisim">
                    İletişim
                  </Link>
                </li>
              </ul>
            </div>
            <div className="inside">
              <h3>Avukat İZMİR HUKUK BÜROSU</h3>
              <p>
                İzmir avukat arıyorum diyorsanız bize ulaşın ve hukuki
                sorunlarınıza çözüm bulun.{" "}
                <a href="https://avukatmervekolman.com/izmir-bosanma-avukati">
                  İzmir Boşanma Avukatı
                </a>
                , <a href="https://lutfikurt.av.tr/">İzmir Ceza Avukatı</a>,{" "}
                <Link to="/calisma-alanlari/deport-avukati">
                  İzmir Deport Avukatı
                </Link>{" "}
                <Link to="/calisma-alanlari/icra-iflas-avukati">
                  İzmir İcra İflas Avukatı
                </Link>{" "}
                <Link to="/calisma-alanlari/gayrimenkul-avukati">
                  İzmir Gayrimenkul Avukatı
                </Link>{" "}
                <Link to="/calisma-alanlari/is-avukati">İzmir İş Avukatı</Link>{" "}
                ve diğer hukuki konularda çalışıyoruz.
              </p>
            </div>
          </div>
          <div className="stripe white" />
          <div className="footer-bottom">
            <span>
              <Link to="/">© {y} İzmir, Avukat İpek Kul</Link>
              <a
                href="https://www.anayasa.gov.tr/"
                target="_blank"
                rel="noreferrer"
              >
                Anayasa Mahkemesi
              </a>
              <a
                href="https://izmir.adalet.gov.tr/"
                target="_blank"
                rel="noreferrer"
              >
                İzmir Adalet Sarayı
              </a>
              <a
                href="https://www.barobirlik.org.tr/"
                target="_blank"
                rel="noreferrer"
              >
                Barolar Birliği
              </a>
              <a
                href="https://www.yargitay.gov.tr/"
                target="_blank"
                rel="noreferrer"
              >
                Yargıtay Başkanlığı
              </a>
              <a
                href="https://www.danistay.gov.tr/"
                target="_blank"
                rel="noreferrer"
              >
                Danıştay Başkanlığı
              </a>
              <a
                href="https://www.uyap.gov.tr/"
                target="_blank"
                rel="noreferrer"
              >
                UYAP Bilişim Sistemi
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
